import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import PreviewIcon from '@mui/icons-material/Preview';
import IconButton from '@mui/material/IconButton';

function CanvasPreview() {
	const [open, setOpen] = useState(false);
	const [image, setImage] = useState(null);

	const dpSketch = () => window.displaySketch; // helper to get the p5 instance of displaySketch

	const getCanvasData = () => {
		const canvasId = dpSketch()?.canvas.id;
		const canvasElement = document.getElementById(canvasId);
		if (!canvasElement) alert('Sorry couldnt get Bitmap data, please try again.');
		// @ts-ignore
		const canvasData = canvasElement.toDataURL('image/png');
		// console.log({ canvasData, canvasId });
		return canvasData;
	};

	const onOpen = () => {
		setImage(null);
		setOpen(true);
		setImage(getCanvasData());
	};

	const onClose = () => setOpen(false);

	return (
		<>
			<IconButton aria-label="preview" color="inherit" onClick={onOpen}>
				<PreviewIcon />
			</IconButton>

			<Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box
					sx={{
						position: 'absolute' as 'absolute',
						top: '40%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '90%',
						bgcolor: 'background.paper',
						border: '1px solid #000',
						boxShadow: 24,
						borderRadius: 3,
						p: 3,
					}}
				>
					<Typography id="modal-modal-title" variant="h6" component="h2" align="center">
						Bitmap Preview
					</Typography>
					<Divider variant="middle" light />

					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							margin: '20px 0 10px',
						}}
					>
						<img src={image} alt="preview" />
					</Box>
				</Box>
			</Modal>
			{/* <div>
				<button onClick={saveCanvasDataToLocalStorage}>Save</button>
			</div> */}
		</>
	);
}

export default CanvasPreview;
