// @ts-nocheck
/*
PIXEL ART CREATOR
Marion Walton, University of Cape Town
Creative Code
http://ikamvacodes.wordpress.com

Converted to p5.js and further adapted for use as a bitmap editor by
Stephen Wolff, Max Gate Digital Ltd

Converted to typescript and further adapted for use as a bitmap editor in React by
Michael Pretorius, Younglings Digital Studios
*/

import { Sketch, P5CanvasInstance } from '@p5-wrapper/react';
// import { toast } from 'react-toastify';

type Colour = any; // TODO: provide an appropriate type for Colour
type Image = any; // TODO: provide an appropriate type for Image

// PARAMETERS
export interface Parameters {
	width: number;
	height: number;
	snapshot?: string;
	background_image?: string;
	bgcolour?: string;
}
let parameters = {
	width: 100,
	height: 100,
	snapshot: undefined,
	background_image: undefined,
	bgcolour: undefined,
};
export const setParameters = (params: Parameters) => {
	parameters = params;
};
export const getParameters = () => parameters;

export const editSketch: Sketch = function (p: P5CanvasInstance) {
	// const debug = false;
	let img: Image | undefined;
	let backgroundImg: Image | undefined;

	// set up array of beads
	const rows = parameters.height;
	const columns = parameters.width;

	let backgroundColour: Colour = p.color(0);
	let pencilColour: Colour = p.color(255);

	// set the drawing size in pixels for the pixels
	let drawSize = 10; // num pixels width and height per pixel
	let pencilSizeX = 1;
	let pencilSizeY = 1;
	const maxSize = 20;
	const minSize = 1;

	const canvasWidth = 310;
	const canvasHeight = 310;
	let canvasCentreX = canvasWidth / 2;
	let canvasCentreY = canvasHeight / 2;
	let viewCenterX = canvasCentreX;
	let viewCenterY = canvasCentreY;
	let screenXAdjust: number;
	let screenYAdjust: number;

	let colours: Colour[][] = [];
	const frameColour: Colour = p.color(244);

	let mouseStartDragX = -1;
	let mouseStartDragY = -1;
	p.dragStart = false;
	p.DRAW_MODE = 1;
	p.HAND_MODE = 2;
	p.toolMode = p.DRAW_MODE;

	p.preload = () => {
		if (parameters.snapshot !== undefined && parameters.snapshot !== null) {
			img = p.loadImage(parameters.snapshot);
		}
		if (parameters.background_image !== undefined && parameters.background_image !== null) {
			backgroundImg = p.loadImage(parameters.background_image);
		}
	};

	p.setup = () => {
		p.createCanvas(canvasWidth, canvasHeight);
		p.frameRate(30);
		updateScreenAdjust();

		p.toolMode = p.DRAW_MODE;

		if (img) {
			p.loadPixels();

			// We must also call loadPixels() on the PImage since we are going to read its pixels.
			img.loadPixels();
		}

		for (var i = 0; i < rows; i++) {
			colours[i] = [];
			for (var j = 0; j < columns; j++) {
				if (img !== undefined) {
					var loc = (j + i * img.width) * 4;

					// Get the R,G,B values from image
					var r = img.pixels[loc];
					var g = img.pixels[loc + 1];
					var b = img.pixels[loc + 2];

					if (r !== undefined) {
						colours[i][j] = p.color(r, g, b);
					} else {
						colours[i][j] = undefined;
					}
				} else {
					colours[i][j] = undefined;
					// use transparent?
					//p.color(0, 0, 0, 255);
				}
			}
		}
	};

	p.draw = () => {
		p.smooth();

		//draw background
		p.background(backgroundColour);

		// show background image within square
		if (backgroundImg) {
			// Displays the image at point (0, height/2) at half size
			p.image(backgroundImg, getScreenX_Left(0), getScreenY_Top(0), columns * drawSize, rows * drawSize);
			//0, height / 2, backgroundImg.width / 2, img.height / 2);
			//p.background(backgroundImg);
		}

		window?.displaySketch?.updateColours(colours, backgroundColour);

		//use 'for' loop to draw rows down the y axis
		for (var i = 0; i < rows; i++) {
			for (var j = 0; j < columns; j++) {
				if (colours[i][j] !== undefined) {
					drawBead(j, i, colours[i][j]);
				}
			}
		}

		// mousePressed replaced with mouseIsPressed
		if (p.mouseIsPressed || p.touchIsDown) {
			// toast(`p.draw mouseIsPressed ${p.toolMode} ${p.dragStart}`, { closeOnClick: true, autoClose: 1000, toastId: 'p.drawmouseIsPressed' });
			if (p.toolMode === p.DRAW_MODE) {
				if (p.mouseButton === p.LEFT) {
					setColour();
				}
			}

			if (p.toolMode === p.HAND_MODE) {
				if (p.dragStart) {
					if (p.mouseX > 0 && p.mouseX < canvasWidth && p.mouseY > 0 && p.mouseY < canvasHeight) {
						// toast(`p.draw move`, { closeOnClick: true, autoClose: 1000, toastId: 'p.drawmove' });
						viewCenterX -= mouseStartDragX - p.mouseX;
						viewCenterY -= mouseStartDragY - p.mouseY;
						mouseStartDragX = p.mouseX;
						mouseStartDragY = p.mouseY;
						updateScreenAdjust();
					}
				}
			}
		}

		// draw frame
		p.noFill();
		p.stroke(frameColour);
		p.rect(getScreenX_Left(0), getScreenY_Top(0), columns * drawSize, rows * drawSize);

		// draw pencil
		if (p.toolMode === p.DRAW_MODE) {
			drawPencil();
		}
	};

	p.touchStarted = () => {
		// toast(`p.touchStarted, ${p.toolMode}`, { closeOnClick: true, autoClose: 1000 });
		mousePressed();
	};
	p.mousePressed = () => {
		// toast(`p.mousePressed, ${p.toolMode}`, { closeOnClick: true, autoClose: 1000 });
		mousePressed();
	};

	const mousePressed = () => {
		if (p.toolMode === p.HAND_MODE) {
			if (p.mouseX > 0 && p.mouseX < canvasWidth && p.mouseY > 0 && p.mouseY < canvasHeight && p.dragStart === false) {
				// toast(`p.mousePressed, mark start hand move`, { closeOnClick: true, autoClose: 1000 });
				// mark start hand move
				mouseStartDragX = p.mouseX;
				mouseStartDragY = p.mouseY;
				p.dragStart = true;
			}
		}
	};

	p.mouseReleased = () => {
		// toast(`mouseReleased`, { closeOnClick: true, autoClose: 1000 });
		if (p.toolMode === p.HAND_MODE && p.dragStart) {
			resetMouseDrag();
		}
	};

	/**
	 * zoom can be +1 or -1
	 */
	p.updateZoom = (zoom: number) => {
		if (drawSize + zoom <= maxSize && drawSize + zoom >= minSize) {
			drawSize = drawSize + zoom;
			updateScreenAdjust();
		}
	};

	// BACKGROUND helpers
	// ========================================
	p.setBackgroundColour = (colour: Colour) => {
		backgroundColour = p.color(p5ColorValues(colour) || colour);
	};
	p.getBackgroundColour = () => backgroundColour;
	p.getBackgroundColourHex = () => rgbaToHexa(backgroundColour.toString());

	p.setBackgroundImage = (imageUrl: string) => {};

	// PENCIL helpers
	// ========================================
	p.setPencilColour = (colour: Colour) => {
		pencilColour = p.color(p5ColorValues(colour) || colour);
	};
	p.getPencilColour = () => pencilColour;
	p.getPencilColourHex = () => rgbaToHexa(pencilColour.toString());

	p.getPensilSize = (): number => ({ x: pencilSizeX, y: pencilSizeY });
	p.setPensilSize = (width: number, height: number) => {
		pencilSizeX = width;
		pencilSizeY = height;
	};

	p.toggleMode = () => {
		if (p.toolMode === p.DRAW_MODE) {
			p.toolMode = p.HAND_MODE;
			p.cursor(p.HAND);
		} else {
			p.toolMode = p.DRAW_MODE;
			p.cursor(p.ARROW);
			resetMouseDrag();
		}
	};

	// DRAWSIZE helpers
	// ========================================
	p.getDrawSize = (): number => {
		return drawSize;
	};
	p.setDrawSize = (newSize: number) => {
		drawSize = newSize;
	};

	function updateScreenAdjust() {
		screenXAdjust = viewCenterX - (columns / 2) * drawSize;
		screenYAdjust = viewCenterY - (rows / 2) * drawSize;
	}

	function resetMouseDrag() {
		// toast(`resetMouseDrag`, { closeOnClick: true, autoClose: 1000 });
		p.dragStart = false;
		mouseStartDragX = -1;
		mouseStartDragY = -1;
	}

	function getScreenX_Left(x: number) {
		return x * drawSize + screenXAdjust;
	}

	function getArrayX(screenX_Left: number) {
		return p.int((screenX_Left - screenXAdjust) / drawSize);
	}

	function getScreenY_Top(y: number) {
		var screenY = y * drawSize + screenYAdjust;
		if (mouseStartDragX !== -1 && mouseStartDragY !== -1) {
		}
		return screenY;
	}

	function getArrayY(screenY_Top: number) {
		return p.int((screenY_Top - screenYAdjust) / drawSize);
	}

	function drawBead(colvalue: number, rowvalue: number, beadColour: Colour) {
		var screenX = getScreenX_Left(colvalue);
		var screenY = getScreenY_Top(rowvalue);

		p.fill(beadColour);
		p.noStroke();

		p.rect(screenX, screenY, drawSize, drawSize);
	}

	function drawPencil() {
		var rowvalue = getArrayY(p.mouseY);
		var colvalue = getArrayX(p.mouseX);

		// inside bitmap size?
		for (var beadX = 0; beadX < pencilSizeX; beadX++) {
			for (var beadY = 0; beadY < pencilSizeY; beadY++) {
				if (colvalue >= 0 && colvalue + beadX < columns && rowvalue >= 0 && rowvalue + beadY < rows) {
					var screenX = getScreenX_Left(colvalue + beadX);
					var screenY = getScreenY_Top(rowvalue + beadY);

					// inside visible area?
					if (screenX >= 0 && screenX < p.width && screenY >= 0 && screenY < p.height) {
						if (beadX === 0 && beadY === 0) {
							drawPencilDottedRect(screenX, screenY);
						}
					}
				}
			}
		}
	}

	function drawPencilDottedRect(_x: number, _y: number) {
		for (var x = 0; x <= drawSize * pencilSizeX; x += 3) {
			var x1 = _x + x;
			var y1 = _y;
			var y2 = _y + drawSize * pencilSizeY;
			p.point(x1, y1);
			p.point(x1, y2);
		}

		for (var y = 0; y <= drawSize * pencilSizeY; y += 3) {
			var x3 = _x;
			var x4 = _x + drawSize * pencilSizeX;
			var y3 = _y + y;
			p.point(x3, y3);
			p.point(x4, y3);
		}
	}

	function setColour() {
		var rowvalue = getArrayY(p.mouseY);
		var colvalue = getArrayX(p.mouseX);

		// inside bitmap size?
		for (var beadX = 0; beadX < pencilSizeX; beadX++) {
			for (var beadY = 0; beadY < pencilSizeY; beadY++) {
				if (colvalue >= 0 && colvalue + beadX < columns && rowvalue >= 0 && rowvalue + beadY < rows) {
					var screenX = getScreenX_Left(colvalue + beadX);
					var screenY = getScreenY_Top(rowvalue + beadY);

					// inside visible area?
					if (screenX >= 0 && screenX < p.width && screenY >= 0 && screenY < p.height) {
						colours[rowvalue + beadY][colvalue + beadX] = pencilColour;
					}
				}
			}
		}
	}

	// @ts-ignore
	window.editSketch = p;
};

export const displaySketch: Sketch = (p: P5CanvasInstance) => {
	const canvasWidth: number = parameters.width;
	const canvasHeight: number = parameters.height;
	let colours: Colour[][] = [];
	let backgroundColour: Colour = p.color(0);

	p.setup = function () {
		p.createCanvas(canvasWidth, canvasHeight);
		p.pixelDensity(1);
	};

	p.draw = function () {
		p.smooth();

		//draw background
		p.background(backgroundColour);

		//use 'for' loop to draw rows down the y axis
		for (var i = 0; i < colours.length; i++) {
			for (var j = 0; j < colours[i].length; j++) {
				if (colours[i][j] !== undefined) {
					drawBead(j, i, colours[i][j]);
				}
			}
		}
	};

	p.updateColours = (c: Colour[][], bc: Colour): void => {
		colours = c;
		backgroundColour = bc;
	};

	function drawBead(colvalue: number, rowvalue: number, beadColour: Colour) {
		p.fill(beadColour);
		p.noStroke();

		p.rect(colvalue, rowvalue, 1, 1);
	}

	// @ts-ignore
	window.displaySketch = p;
};

export const calculateDrawSize = (bitmap_width: number, bitmap_height: number) => {
	let draw_size = 10;
	let canvas_width = 280;
	let canvas_height = 280;

	// expand or reduce
	if (bitmap_width * draw_size < canvas_width && bitmap_height * draw_size < canvas_height) {
		while (bitmap_width * draw_size < canvas_width && bitmap_height * draw_size < canvas_height) {
			draw_size = draw_size + 1;
		}
	} else if (bitmap_width * draw_size > canvas_width && bitmap_height * draw_size > canvas_height) {
		while (bitmap_width * draw_size > canvas_width && bitmap_height * draw_size > canvas_height) {
			draw_size = draw_size - 1;
		}
	}

	// one smaller is best?
	draw_size = draw_size - 1;

	draw_size = Math.max(draw_size, 1);
	return draw_size;
};

export const rgbaToHexa = (rgba: string, forceRemoveAlpha = false) => {
	return (
		'#' +
		rgba
			.replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
			.split(',') // splits them at ","
			.filter((string, index) => !forceRemoveAlpha || index !== 3)
			.map(string => parseFloat(string)) // Converts them to numbers
			.map((number, index) => (index === 3 ? Math.round(number * 255) : number)) // Converts alpha to 255 number
			.map(number => number.toString(16)) // Converts numbers to hex
			.map(string => (string.length === 1 ? '0' + string : string)) // Adds 0 when length of one number is 1
			.join('')
	); // Puts the array to togehter to a string
};

export const hexToRgba = (hex: string) => {
	let c: any;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split('');
		if (c.length === 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = '0x' + c.join('');
		return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
	}
	throw new Error('Bad Hex');
};

// return array of [r,g,b,a] from any valid color. if failed returns undefined
export const colorValues = (color: any) => {
	if (!color) return;
	if (color.toLowerCase() === 'transparent') return [0, 0, 0, 0];
	if (color[0] === '#') {
		if (color.length < 7) {
			// convert #RGB and #RGBA to #RRGGBB and #RRGGBBAA
			color = '#' + color[1] + color[1] + color[2] + color[2] + color[3] + color[3] + (color.length > 4 ? color[4] + color[4] : '');
		}
		return [parseInt(color.substr(1, 2), 16), parseInt(color.substr(3, 2), 16), parseInt(color.substr(5, 2), 16), color.length > 7 ? parseInt(color.substr(7, 2), 16) / 255 : 1];
	}
	if (color.indexOf('rgb') === -1) {
		// convert named colors
		var temp_elem = document.body.appendChild(document.createElement('fictum')); // intentionally use unknown tag to lower chances of css rule override with !important
		var flag = 'rgb(1, 2, 3)'; // this flag tested on chrome 59, ff 53, ie9, ie10, ie11, edge 14
		temp_elem.style.color = flag;
		if (temp_elem.style.color !== flag) return; // color set failed - some monstrous css rule is probably taking over the color of our object
		temp_elem.style.color = color;
		if (temp_elem.style.color === flag || temp_elem.style.color === '') return; // color parse failed
		color = getComputedStyle(temp_elem).color;
		document.body.removeChild(temp_elem);
	}
	if (color.indexOf('rgb') === 0) {
		if (color.indexOf('rgba') === -1) color += ',1'; // convert 'rgb(R,G,B)' to 'rgb(R,G,B)A' which looks awful but will pass the regxep below
		// eslint-disable-next-line no-useless-escape
		return color.match(/[\.\d]+/g).map(function (a) {
			return +a;
		});
	}
};

// return colorValues() value but alpha from 0 to 1 is converted to 0 to 255
export const p5ColorValues = (color: any) => {
	let arr = colorValues(color);
	if (arr) {
		if (arr.length === 4) {
			arr[3] = Math.round(arr[3] * 255);
		}
		return arr;
	}
};
