import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
	palette: {
		primary: {
			main: '#FFC100',
			// main: '#548687',
		},
		secondary: {
			main: '#FBD561',
		},
		error: {
			main: red.A400,
		},
		text: {
			primary: '#ddd',
			secondary: '#ffffff',
		},
		background: {
			default: '#333333',
			paper: '#1A1A1A',
		},
	},
	typography: {
		fontFamily: ['Poppins'].join(','),
	},
});

export default theme;
