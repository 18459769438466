import { useEffect } from 'react';
import { ReactP5Wrapper } from '@p5-wrapper/react';
import { calculateDrawSize, displaySketch, editSketch } from '../libs/BitmapEditor/bitmapMaker3';
import styled from 'styled-components';
import { useForceUpdate } from '../hooks/forceUpdate';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { simulateMouseClick } from '../helpers/click';
import { getCreateWith } from '../helpers/createWith';
import MinusIcon from '../assets/minus.png';
import PlusIcon from '../assets/plus.png';
import CustomButton from './Button';
import Tools from './Tools';

declare global {
	interface Window {
		editSketch: any;
		displaySketch: any;
	}
}

function Canvas() {
	const handleForceupdateMethod = useForceUpdate();

	const createWith = getCreateWith();
	const p5 = () => window.editSketch; // helper to get the p5 instance of editSketch
	const dpSketch = () => window.displaySketch; // helper to get the p5 instance of displaySketch

	const saveCanvas = async () => {
		if (!createWith) return;

		const canvasId = dpSketch()?.canvas.id;
		const canvasElement = document.getElementById(canvasId);
		if (!canvasElement) alert('Error saving image to local storage.');
		// @ts-ignore
		const canvasData = canvasElement.toDataURL('image/png');
		// console.log({ canvasData, canvasId });

		try {
			// @ts-ignore
			await createWith.post(canvasData);
			createWith.close();
		} catch (error) {
			alert(`Error saving image: \n ${error.message}`);
			console.error(error);
		}
	};

	useEffect(() => {
		if (p5()) {
			const drawSize = calculateDrawSize(100, 100);
			p5().setDrawSize(drawSize ?? 1);
			updateZoom(1);
			setTimeout(() => {
				handleForceupdateMethod();
			}, 500);
			// simulate touch for mobile webView
			simulateMouseClick(document);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateZoom = (zoom: number) => {
		p5()?.updateZoom(zoom);
		handleForceupdateMethod();
	};

	return (
		<Screen>
			<Buttons>
				<CustomButton onClick={() => updateZoom(-1)} image={MinusIcon} label="Zoom Out" />
				<Typography mr={1}>{p5()?.getDrawSize() || 0}</Typography>
				<CustomButton onClick={() => updateZoom(1)} image={PlusIcon} label="Zoom In" />
			</Buttons>

			<Box display="none">
				<ReactP5Wrapper sketch={displaySketch} />
			</Box>
			<EditCanvas>
				<ReactP5Wrapper sketch={editSketch} />
			</EditCanvas>

			<Tools />

			<Box mt={2}>
				<Button variant="outlined" onClick={saveCanvas}>
					Save
				</Button>
			</Box>
		</Screen>
	);
}

export default Canvas;

const Screen = styled.div`
	margin: 0 auto;
`;

const EditCanvas = styled.div`
	margin: 15px 0;
	max-width: 310px;
	max-height: 310px;
	border-radius: 15px;
	overflow: hidden;
`;

const Buttons = styled.div`
	display: flex;
	flew-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 15px;
`;
