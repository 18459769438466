import Box from '@mui/material/Box';
import Canvas from './Canvas';
import { CircularProgress, Typography } from '@mui/material';
import theme from '../theme';
import { getCreateWith } from '../helpers/createWith';
import { useEffect, useState } from 'react';

// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

function Main() {
	const [isApp, setisApp] = useState(process.env.NODE_ENV === 'development');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		let attempts = 0;

		const checkDom = () => {
			if (attempts < 7 && !isApp) {
				if (getCreateWith()) {
					setisApp(true);
					setLoading(false);
				} else {
					attempts++;
					setTimeout(checkDom, 500);
				}
			} else {
				setLoading(false);
			}
		};
		checkDom();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				height: '100vh',
				justifyContent: 'center',
			}}
		>
			{loading ? (
				<CircularProgress color="primary" />
			) : isApp ? (
				<Canvas />
			) : (
				<Box my={6} mx={2}>
					<Typography variant="h5" component="h2" align="center" mb={1}>
						Sorry
					</Typography>
					<Typography component="h2" align="center">
						This website is meant to be used within the <span style={{ color: theme.palette.primary.main }}>Create With</span> App Exclusively
					</Typography>
				</Box>
			)}
			{/* <ToastContainer /> */}
		</Box>
	);
}

export default Main;
