export const simulateMouseClick = targetNode => {
	function triggerMouseEvent(targetNode, eventType) {
		var clickEvent = document.createEvent('MouseEvents');
		clickEvent.initEvent(eventType, true, true);
		targetNode.dispatchEvent(clickEvent);
	}
	['mouseover', 'mousedown', 'mouseup', 'click'].forEach(function (eventType) {
		triggerMouseEvent(targetNode, eventType);
	});
};
