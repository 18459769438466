import { CreateWith } from '../types/createWith';

declare const window: any;
declare const global: any;
declare const globalThis: any;

const g = typeof window === 'object' ? window : typeof globalThis === 'object' ? globalThis : typeof global === 'object' ? global : null;

export const getCreateWith = (): CreateWith | undefined => {
	if (typeof g !== 'undefined' && g?.createWith) return g.createWith;
	return undefined;
};
